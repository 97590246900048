import React from 'react';
import './Compliance.css';
import complianceImage from '../../assets/compliance1.png'; // Add your image path here

const Compliance = () => {
    return (
        <div className="compliance-page">
            <section className="compliance-header">
                <div className="content">
                    <h1>Automate Your Compliance Processes</h1>
                    <ul>
                        <li>Stay ahead of quality control and legal standards by automating your compliance management with MedicLedger.</li>
                        <li>Ensure that all your products meet necessary regulatory requirements and industry standards without the hassle of manual tracking.</li>
                        <li>Easily maintain certifications, track expiry dates, and comply with FDA, FSSAI, and other global standards.</li>
                        <li>Reduce errors, ensure faster responses to audits, and improve the overall efficiency of your operations by automating compliance processes.</li>
                        <li>Whether you're a manufacturer, supplier, or farmer, MedicLedger provides the tools to effortlessly stay compliant with regulatory bodies.</li>
                        <li>Save time and resources while maintaining the highest standards of quality, all with one integrated platform.</li>
                    </ul>
                    <button className="cta-button">Get Started with Compliance</button>
                </div>
                <img src={complianceImage} alt="Compliance Automation" className="compliance-image" />
            </section>
        </div>
    );
}

export default Compliance;
