import React from 'react';
import './Tracking.css';
import trackingImage from '../../assets/tracking1.png';

const Tracking = () => {
    return (
        <div className="tracking-page">
            <section className="page-header">
                <div className="content">
                    <h1>Track Your Products in Real-Time</h1>
                    <p>With MedicLedger, gain complete transparency over your products’ journey across the supply chain.</p>
                    <ul>
                        <li>Monitor every step of your product’s journey, from farm to shelf.</li>
                        <li>Track movements, condition, and regulatory compliance at each stage.</li>
                        <li>Know exactly where your products are at every moment, ensuring full transparency.</li>
                        <li>Stay ahead of potential disruptions with real-time data updates and insights.</li>
                        <li>Gain visibility into inventory levels, product conditions, and compliance status across all stages of the supply chain.</li>
                    </ul>
                    <button className="cta-button">Start Tracking</button>
                </div>
                <img src={trackingImage} alt="Supply Chain Tracking" className="tracking-image" />
            </section>
        </div>
    );
}

export default Tracking;
