import React from 'react';
import './Onboarding.css';
import onboardingImage from '../../assets/onboarding.png'; // Add your image path here

const Onboarding = () => {
    return (
        <div className="onboarding-page">
            <section className="page-header">
                <div className="content">
                    <h1>Onboard Your Products with Ease</h1>
                    <p>Start tracking your products from day one with MedicLedger’s user-friendly onboarding process.</p>
                    <ul>
                        <li>Quickly register your products with key details such as origin, batch numbers, and expiration dates.</li>
                        <li>Our automated process ensures seamless entry and reduces manual errors.</li>
                        <li>Track product information, inventory, and product lifecycle with ease.</li>
                        <li>Whether you're a farmer, supplier, or manufacturer, onboarding is simple and scalable for your business.</li>
                        <li>Start managing and tracking your products right away, with complete traceability from farm to consumer.</li>
                    </ul>
                    <button className="cta-button">Get Started</button>
                </div>
                <img src={onboardingImage} alt="Onboarding Products" className="onboarding-image" />
            </section>
        </div>
    );
}

export default Onboarding;
