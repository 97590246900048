import React from 'react';
import './SignUp.css'; // You can create the corresponding CSS file
import signin from '../../assets/signin.jpg';

const SignUp = () => {
    return (
        <div className="signup-container">
            <section className="left-section">
                <img src={signin} alt="MedicLedger Vision" className="signup-image" />
            </section>
            <section className="right-section">
                <div className="form-container">
                    <h2>Sign Up for MedicLedger</h2>
                    <form>
                        <div className="form-group">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" placeholder="First Name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" placeholder="Last Name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" placeholder="Email" required />
                        </div>
                        <button type="submit" className="cta-button-signup">Sign Up</button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default SignUp;
