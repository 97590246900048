import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            <h1>Contact Us</h1>
            <p>If you have any questions, feel free to reach out to us!</p>

            {/* Email Contact Section */}


            {/* Contact Form */}
            <form>
                <input type="text" placeholder="Your Name" />
                <input type="email" placeholder="Your Email" />
                <textarea placeholder="Your Message"></textarea>
                <button type="submit">Submit</button>
            </form>
            <div className="email-contact">
                <p>If you prefer, you can also reach us via email at:</p>
                <a href="mailto:info@medicledger.com" className="email-link">info@medicledger.com</a>
            </div>
        </div>
    );
};

export default Contact;
