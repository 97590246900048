// src/Header.js
import React from 'react';
import './Header.css';  // Make sure to create corresponding CSS for styling
import logo from '../../assets/medicledger.png';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="MedicLedger Logo" />
            </div>
            <nav className="navbar">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/features">Features</Link></li>
                    {/* <li>
                        <Link to="/ai-assistant">
                            <i className="fa fa-robot"></i> 
                        </Link>

                    </li> */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
