import React from 'react';
import './AboutUs.css';
import saroj from '../../assets/saroj.png';

const AboutUs = () => {
    return (
        <div className="about-us">
            <h1>About Us</h1>
            <p>We are a leading company transforming the herbal supplement industry using blockchain technology.</p>
            <p>Our mission is to ensure quality, transparency, and trust in every step of the process.</p>

            <section>
                <div className="founder-section">
                    <div className="founder-image">
                        <img src={saroj} alt="Founder Image" />
                    </div>
                    <div className="founder-bio">
                        <h2 className="founder-name">Saroj Rout</h2>
                        <h3 className="founder-title">Founder & CEO</h3>
                        <p>Saroj is an accomplished entrepreneur with a proven track record in building multiple successful startups. With deep expertise in AI, machine learning, and blockchain technology, he has gained invaluable experience from leading top tech companies. Having contributed to over $100M in annual recurring revenue (ARR), Saroj leverages his technical acumen to drive innovative, user-friendly solutions at MedicLedger.</p>
                    </div>
                </div>

                <div className="cta">
                    <a href="contact" className="cta-button">Contact Us</a>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;
