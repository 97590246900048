import React from 'react';
import './Features.css';
import medicledgerplatform from '../../assets/medicledger-platform.png';

const Features = () => {
    return (
        <div className="features">
            <div className="features-container">
                <div className="feature-list">
                    <div className="feature">
                        <h3>End-to-End Traceability</h3>
                        <p>Track your products from farm to consumer with full transparency.</p>
                    </div>
                    <div className="feature">
                        <h3>Regulatory Compliance</h3>
                        <p>Automate compliance with FDA and FSSAI standards.</p>
                    </div>
                    <div className="feature">
                        <h3>Real-Time Monitoring</h3>
                        <p>Monitor inventory, sales, and supply chain in real-time.</p>
                    </div>
                </div>
                <div className="image-container">
                    <img src={medicledgerplatform} alt="MedicLedger Features" className="features-image" />
                </div>
            </div>
        </div>
    );
};

export default Features;
